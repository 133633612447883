//---------------------------------------------------------
// Imports
//---------------------------------------------------------
import { Box } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DirectionalLine } from './DirectionalLine';

//---------------------------------------------------------
// Component
//---------------------------------------------------------
export default function LookAtMe() {
	const [tile_count, set_tile_count] = useState<number>(0);
	const [tile_size, set_tile_size] = useState<number>(0);
	const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
	const [is_hover, set_is_hover] = useState(false);
	const [autoMousePos, setAutoMousePos] = useState({ x: 0, y: 0 });
	const box_ref = useRef<HTMLDivElement>(null);
	const [width, set_width] = useState(0);
	const [height, set_height] = useState(0);

	const calculateTiles = useCallback(() => {
		if (box_ref.current) {
			const box_width = box_ref.current.clientWidth;
			const box_height = box_ref.current.clientHeight;

			set_width(box_width);
			set_height(box_height);

			const tile_size = Math.max(width, height) / 24;
			const cols = Math.ceil(width / tile_size);
			const rows = Math.ceil(height / tile_size);

			set_tile_count(cols * rows);
			set_tile_size(tile_size);
		}
	}, [width, height]);

	useEffect(() => {
		const handleMouseMove = (e: MouseEvent) => {
			setMousePos({
				x: e.clientX,
				y: e.clientY,
			});
		};

		const handleTouchMove = (e: TouchEvent) => {
			const touch = e.touches[0];
			setMousePos({
				x: touch.clientX,
				y: touch.clientY,
			});
		};

		calculateTiles();

		window.addEventListener('resize', calculateTiles);
		window.addEventListener('mousemove', handleMouseMove);
		window.addEventListener('touchmove', handleTouchMove);
		window.addEventListener('touchstart', handleTouchMove);

		let animationFrameId: number;
		const radius = Math.max(window.innerWidth, window.innerHeight);
		const center = { x: radius, y: radius };

		const animate = (timestamp: number) => {
			if (!is_hover) {
				const newAngle = (timestamp * 0.001) % (2 * Math.PI);
				const newX = center.x + radius * Math.cos(newAngle);
				const newY = center.y + radius * Math.sin(newAngle);

				setAutoMousePos({ x: newX, y: newY });
			}
			animationFrameId = requestAnimationFrame(animate);
		};

		animationFrameId = requestAnimationFrame(animate);

		return () => {
			window.removeEventListener('resize', calculateTiles);
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('touchmove', handleTouchMove);
			window.removeEventListener('touchstart', handleTouchMove);
			if (animationFrameId) cancelAnimationFrame(animationFrameId);
		};
	}, [is_hover, calculateTiles]);

	const currentMousePos = is_hover ? mousePos : autoMousePos;

	return (
		<Box
			id="pin-board"
			ref={box_ref}
			width="100%"
			height="100%"
			display="flex"
			flexWrap="wrap"
			alignItems="space-between"
			justifyContent="space-between"
			onTouchStart={() => set_is_hover(true)}
			onTouchEnd={() => set_is_hover(false)}
			onMouseEnter={() => set_is_hover(true)}
			onMouseLeave={() => set_is_hover(false)}
			flexGrow={1}
			sx={{
				gap: 0,
				overflow: 'hidden',
				'& > *': {
					flexShrink: 0,
				},
			}}
		>
			{Array.from({ length: tile_count }).map((_, index) => (
				<Box id="tile" key={index} width={tile_size} height={tile_size} position="relative">
					<DirectionalLine area_length={tile_size} start_color="#444444" end_color="#f7a73d" mouseX={currentMousePos.x} mouseY={currentMousePos.y} />
				</Box>
			))}
		</Box>
	);
}
