//---------------------------------------------------------
// Imports
//---------------------------------------------------------
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import logo from '../assets/logo.svg';
import LookAtMe from '../components/LookAtMe';

//---------------------------------------------------------
// Component
//---------------------------------------------------------
export default function HomePage() {
	const [is_portrait, set_is_portrait] = useState(false);
	const [height, set_height] = useState(window.innerHeight);
	const email = 'contact@attentionlab.com';

	useEffect(() => {
		const handleResize = () => {
			set_height(window.innerHeight);
			set_is_portrait(window.innerWidth < 700);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<Box bgcolor="black" width="100%" height={height} display="flex" justifyContent="center" overflow="hidden">
			<Stack width="100%" maxWidth="1000px" height="100%" alignItems="center" padding="20px" gap="20px">
				<Box width="100%" display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" gap={2}>
					<Box component="img" src={logo} width="230px" />
					<Typography variant="subtitle1" color="white" whiteSpace="normal">
						Full-stack AI & Web3 development {is_portrait && <br />} supporters for your vision
					</Typography>
				</Box>

				<LookAtMe />

				<Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="body2" color="white">
						{'© Attention Lab.'}
					</Typography>
					<Typography
						variant="body2"
						component="a"
						color="white"
						href={`mailto:${email}`}
						sx={{
							color: 'white',
							textDecoration: 'none',
							'&:hover': {
								color: 'white',
							},
						}}
					>
						{email}
					</Typography>
				</Box>
			</Stack>
		</Box>
	);
}
