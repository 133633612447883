export function getRGBValues(color: string): { r: number; g: number; b: number } {
	if (!color) return { r: 0, g: 0, b: 0 };

	if (color.startsWith('#')) {
		const hex = color.replace('#', '');
		if (hex.length === 3) {
			const r = parseInt(hex[0] + hex[0], 16);
			const g = parseInt(hex[1] + hex[1], 16);
			const b = parseInt(hex[2] + hex[2], 16);
			return { r, g, b };
		}
		if (hex.length === 6) {
			const r = parseInt(hex.slice(0, 2), 16);
			const g = parseInt(hex.slice(2, 4), 16);
			const b = parseInt(hex.slice(4, 6), 16);
			return { r, g, b };
		}
	}

	const rgbMatch = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)/i);
	if (rgbMatch) {
		return {
			r: parseInt(rgbMatch[1]),
			g: parseInt(rgbMatch[2]),
			b: parseInt(rgbMatch[3]),
		};
	}

	return { r: 0, g: 0, b: 0 };
}

export function getInterpolatedColor(startColor: string, endColor: string, ratio: number): string {
	const start = getRGBValues(startColor);
	const end = getRGBValues(endColor);

	const clampedRatio = Math.max(0, Math.min(1, ratio));

	const r = Math.round(start.r + (end.r - start.r) * clampedRatio);
	const g = Math.round(start.g + (end.g - start.g) * clampedRatio);
	const b = Math.round(start.b + (end.b - start.b) * clampedRatio);

	return `rgb(${r}, ${g}, ${b})`;
}
