//---------------------------------------------------------
// Imports
//---------------------------------------------------------
import { useRef } from 'react';
import { getInterpolatedColor } from '../common';

//---------------------------------------------------------
// Component
//---------------------------------------------------------
export function DirectionalLine(props: { area_length: number; start_color: string; end_color: string; mouseX: number; mouseY: number }) {
	const { area_length, start_color, end_color, mouseX, mouseY } = props;

	const maxLineLength = 25;
	const damper = 8;
	const boxRef = useRef<HTMLDivElement>(null);

	const box = boxRef.current;
	const rect = box?.getBoundingClientRect();

	const centerX = rect ? rect.left + rect.width / 2 : 0;
	const centerY = rect ? rect.top + rect.height / 2 : 0;

	const relativeX = mouseX - centerX;
	const relativeY = mouseY - centerY;

	const angle = Math.atan2(relativeY, relativeX);
	const cursorDistance = Math.sqrt(relativeX ** 2 + relativeY ** 2);
	const maxDistance = area_length * damper;

	const distance = Math.max(maxLineLength * (cursorDistance / maxDistance), 1);
	const strength = Math.pow(Math.max(0, Math.min(1, 1 - cursorDistance / maxDistance)), 0.5);
	const endX = Math.cos(angle) * distance;
	const endY = Math.sin(angle) * distance;

	const color = getInterpolatedColor(start_color, end_color, strength);
	const lineWidth = 0.5 + strength * 0.5;
	const distance_from_center = area_length / 2 - cursorDistance;
	const is_hovered = distance_from_center > 0;
	const dot_size = distance_from_center * 0.1;

	return (
		<div ref={boxRef} style={{ width: '100%', height: '100%', position: 'relative' }}>
			<svg
				viewBox={`0 0 ${area_length} ${area_length}`}
				style={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					left: 0,
					top: 0,
				}}
			>
				<line x1={area_length / 2} y1={area_length / 2} x2={area_length / 2 + endX} y2={area_length / 2 + endY} stroke={color} strokeWidth={lineWidth} />
				{is_hovered && <circle cx={area_length / 2} cy={area_length / 2} r={dot_size} fill={color} />}
			</svg>
		</div>
	);
}
