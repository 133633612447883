//---------------------------------------------------------
// Imports
//---------------------------------------------------------
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';

//---------------------------------------------------------
// Component
//---------------------------------------------------------
export default function AppRoot() {
	return (
		<Routes>
			<Route path="/" element={<HomePage />} />
		</Routes>
	);
}

//

